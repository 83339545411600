.spinner-message{
  margin-top: 10px;
  font-size: 12px;
}

.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
  }
  
  .spinner-inner {
    width: 40px;
    height: 40px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  