/* AppStyles.css */

/* General styles */

.main-logo img {
  width: 300px;
}

.tile {
  width: 150px;
}

.main-call {
  font-size: 18px;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #222; /* Dark background color */
  color: #fff; /* Text color */
}

.table-container {
  max-height: 400px;
  overflow: auto;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333; /* Dark background color */
  width: 80%;
  max-width: 300px;
  border: 1px solid #555; /* Darker border color */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4); /* Increased shadow */
  z-index: 9999;
  padding: 20px;
}

.checkbox-symbol {
  position: fixed;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #fff; /* Text color */
  font-size: 20px;
  transition: color 0.3s ease; /* Smooth color transition */
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #fff; /* Text color */
  font-size: 20px;
  transition: color 0.3s ease; /* Smooth color transition */
}

.modal-close-button:hover {
  color: #ff4500; /* Change color on hover */
}

.App {
  text-align: center;
  padding: 20px;
}

h1 {
  margin-bottom: 20px;
}

/* Form styles */
.form-search {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.form-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-login input[type="text"], input[type="password"] {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #555; /* Darker border color */
  border-radius: 5px;
  width: 250px;
  margin: 5px;
  background-color: #444; /* Darker input background */
  color: #fff; /* Text color */
  transition: border-color 0.3s ease, background-color 0.3s ease; /* Smooth transitions */
}

.form-search input[type="text"] {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #555; /* Darker border color */
  border-radius: 5px;
  width: 200px;
  margin-right: 10px;
  background-color: #444; /* Darker input background */
  color: #fff; /* Text color */
  transition: border-color 0.3s ease, background-color 0.3s ease; /* Smooth transitions */
}

.form-search input[type="text"]:focus {
  border-color: #007bff; /* Accent color on focus */
  background-color: #333; /* Darker background on focus */
}

.form-login input[type="text"]:focus, input[type="password"]:focus {
  border-color: #007bff; /* Accent color on focus */
  background-color: #333; /* Darker background on focus */
}

.form-search button[type="submit"] {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.form-login button[type="submit"] {
  margin: 5px;
  width: 250px;
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.form-search button[type="submit"]:hover {
  background-color: #0056b3; /* Darker accent color on hover */
}

/* Spinner styles */
.spinner {
  margin-top: 20px;
  font-size: 18px;
}

/* Responsive styles */
@media screen and (max-width: 600px) {
  .form-search {
    flex-direction: column;
  }

  .form-search input[type="text"],
  button[type="submit"] {
    width: 100%;
    margin: 5px 0;
  }
}
